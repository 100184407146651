
.-mp-layer { position: absolute; left: 0; top: 0; width: 100%; height: 0; display: block; background: transparent; overflow: visible; }
#__select__ { z-index: 1111101000; }
#__panel__ { z-index: 1111101100; position: fixed; }
#__float__ { z-index: 1111101200; }
#__menu__ { z-index: 1111101300; }
#__help__ { z-index: 1111101400; }
#__wait__ { z-index: 1111101500; }
.-mp-lay-tx {} .-mp-lay-tx > * { float: none }
.-mp-lay-fl {} .-mp-lay-fl > * { float: left } .-mp-lay-fl:after{ clear: both; display: table; content: ' '; }
.-mp-lay-fr { position: relative; } .-mp-lay-fr > * { position: absolute}
.-mp-mask-outer {position: relative;display: inline-block;}
.-mp-mask-inner {width: 100%;height: 100%;position: absolute;left: 0;top: 0;}

/* bilex-styles.css */
/* manypage.com - this file automatically generated at 19-08-21 20:23:00 */

#root{bottom:0px;display:flex;left:0px;margin:0px;position:absolute;right:0px;top:0px;}
body{bottom:0px;display:flex;font-family:Quicksand;left:0px;margin:0px;position:absolute;right:0px;top:0px;}
a{text-decoration:none;}
.bilex-logo{width:140px;}
/* .page-contents{background-color:#4ec2ff;border-color:#888888;border-style:solid;border-width:0px 1px 1px 1px;box-shadow:0 5px 10px rgba(0, 0, 0, 0.2);box-sizing:border-box;float:none;margin-top:0px;margin-right:auto;margin-left:auto;max-width:960px;min-height:600px;padding:5px;width:100%;} */
.page-contents{background-color:#4ec2ff;border-color:#888888;border-style:solid;border-width:0px 1px 1px 1px;box-shadow:0 5px 10px rgba(0, 0, 0, 0.2);box-sizing:border-box;float:none;margin-top:0px;margin-right:auto;margin-left:auto;max-width:1100px;min-height:600px;padding:5px;width:1100px;}
.page-contents.light{background-color:#4ec2ff}
.page-contents.dark{background-color:#1f0993}
.back-button-container{margin:10px 0px 10px 0px;width:100%;}
.vert-space-between{display:flex;flex-direction:column;height:100%;justify-content:space-between;min-height:inherit;}
.vert-flex-start{display:flex;flex-direction:column;height:100%;justify-content:flex-start;min-height:inherit;width:100%}
.EL-ACTIVE [name=EL]{opacity:1.000;filter:alpha(opacity=100);}
.EN-ACTIVE [name=EN]{opacity:1.000;filter:alpha(opacity=100);}
.flag.SELECTED{opacity:1.000;filter:alpha(opacity=100);}
.EN-ACTIVE{}
.EL-ACTIVE{}
.EN-ACTIVE .lang-en{}
.EN-ACTIVE .lang-el{display:none;}
.EL-ACTIVE .lang-el{}
.EL-ACTIVE .lang-en{display:none;}
.lang-en{}
.lang-el{}
.plus-heading{color:#1f0993;text-align:center;width:100%;}
.clearfix{overflow-x:auto;overflow-y:auto;}
.clearfix::after{clear:both;content:"";display:table;}

.-hx-check-box > input {position:absolute;top:-3px;left:-4px;width:100%;height:100%;opacity:0.1;}

.hx-table{background-color:#f6f6f6;border-color:#888888;border-style:solid;border-width:0px;color:#444444;font-family:sans-serif;font-size:14px;height:871px;padding:0px 12px 0px 0px;width:1060px;}
.hx-table-sheet{background-color:#f0f0f0;max-height:840px;overflow-y:scroll;position:relative;width:calc(100% + 12px);}
td,th{border-color:#888888;border-style:solid;border-width:0px;box-sizing:border-box;height:24px;line-height:24px;overflow-x:hidden;overflow-y:hidden;padding:2px 4px 2px 2px;vertical-align:middle;}
td {text-align:left}

/* emebedded in page */
.table-head{
  background-color: #ffffff;
  border-color: #cccccc;
  border-style: solid;
  border-width: 0px 0px 3px 0px;
  font-family: Quicksand, sans-serif;
  font-size: 12px;
  width: 1072px;
}
.table-head div{border-color:#888888;border-style:solid;border-width:0px;box-sizing:border-box;height:28px;line-height:24px;overflow-x:hidden;overflow-y:hidden;padding:4px 4px 2px 4px;vertical-align:middle;}
.word{box-sizing:border-box;color:#444;cursor:pointer;font-family: sans-serif;font-size:16px;padding:0px 0px 0px 8px;width:200px;}
.word_all_lower{background-color:#f8ffd1;box-sizing:border-box;color:#1e8900;display:none;font-size:16px;padding:0px 0px 0px 8px;width:120px;}
.zipf{color:#b000e7;overflow-x:hidden;overflow-y:hidden;text-align:right;width:60px;}
.col_odd{background-color:#e4e4e4;}
.counts, .frequency_counts {color:#d64600;overflow-x:hidden;overflow-y:hidden;text-align:right;width:50px;}
.freq_pm{color:#25aa00;overflow-x:hidden;max-width:80px;overflow-y:hidden;text-align:right;width:80px;}
.log_frequency_per_million{overflow-x:hidden;overflow-y:hidden;text-align:right;width:120px;}
.letter_length{color:#fc62ff;overflow-x:hidden;overflow-y:hidden;text-align:right;width:100px;}
.syllables{color:#f16500;display:none;overflow-x:hidden;overflow-y:hidden;text-align:right;width:230px;}
.syl_length{color:#27c300;overflow-x:hidden;overflow-y:hidden;text-align:right;width:70px;}
.stress_pos{color:#fb0062;overflow-x:hidden;overflow-y:hidden;text-align:right;width:73px;}
.phones{color:#0092c1;overflow-x:hidden;overflow-y:hidden;text-align:right;width:140px;}
.phon_length{color:#006dbc;display:none;overflow-x:hidden;overflow-y:hidden;text-align:right;width:90px;}
.unstressed_orth{color:#490060;display:none;overflow-x:hidden;overflow-y:hidden;text-align:right;width:90px;}
.unstressed_phon{color:#009961;display:table-cell;overflow-x:hidden;overflow-y:hidden;text-align:right;width:130px;}
.coltnorth{color:#c13500;display:none;overflow-x:hidden;overflow-y:hidden;text-align:right;width:60px;}
.coltnphon{color:#c800f4;display:none;overflow-x:hidden;overflow-y:hidden;text-align:right;width:60px;}
.OLD20orth, .old20orth{color:#6e7f00;display:table-cell;overflow-x:hidden;overflow-y:hidden;text-align:right;width:80px;}
.OLD20phon, .old20phon{color:#00979e;display:table-cell;overflow-x:hidden;overflow-y:hidden;text-align:right;width:80px;}
.sounds{background-color:#bbbbbb;color:#e2ff2d;display:none;float:left;overflow-x:hidden;overflow-y:hidden;text-align:right;width:80px;}
.translation{background-color:#bbbbbb;color:#00aab2;display:none;float:left;overflow-x:hidden;overflow-y:hidden;text-align:left;text-overflow:ellipsis;white-space:nowrap;width:32px;}
.scroll-bar{background-color:#eeeeee;border-color:#cccccc;border-style:solid;border-width:3px 0px 0px 1px;box-sizing:content-box;height:840px;position:absolute;right:-12px;top:-3px;width:12px;}
.scroll-bar-handle{background-color:#b8b8b8;border-color:#aaaaaa;border-radius:20px;border-style:solid;border-width:1px;box-sizing:border-box;height:24px;left:0px;max-width:100%;min-height:24px;position:absolute;width:100%;}
tr.REPEAT-EVEN{background-color:#e0e0e0;}
tr.REPEAT-EVEN .col_odd{background-color:#d8d8d8;}
td,th{border-color:#888888;border-style:solid;border-width:0px;box-sizing:border-box;height:24px;line-height:24px;overflow-x:hidden;overflow-y:hidden;padding:2px 4px 2px 2px;vertical-align:middle;}
tr{border-color:#888888;border-style:solid;border-width:0px;}
.filter_box{background-color:#eeeeee;border-color:#cccccc;border-radius:4px;border-style:solid;border-width:1px;box-sizing:border-box;clear:both;height:auto;margin:0px 2px 2px 2px;padding:0px;width:24%;}
.active-check{float:left;margin:3px;position:relative;}
.-hx-popmenu{cursor:pointer;height:32px;position:relative;}
.condition-pop{clear:both;margin:0px 0px 3px 3px;width:70px;}
.-hx-text-entry{border-color:#414c58;border-radius:2px;border-style:solid;border-width:1px;box-sizing:border-box;color:#282828;font-size:12px;height:32px;line-height:32px;padding:0px 0px 0px 6px;}
.value-entry{margin:0px 0px 0px 6px;width:100px;}
.-hx-check-box{background-color:#f4f4f4;border-color:#9aabb3;border-radius:2px;border-style:solid;border-width:1px;cursor:pointer;height:20px;position:relative;width:20px;}
.words{box-sizing:border-box;font-size:16px;padding:0px 0px 0px 8px;width:120px;}
.col-name-lbl{cursor:pointer;font-size:10px;height:22px;line-height:22px;margin:0px 0px 0px 4px;padding:0px;text-align:left;}
.-hx-popmenu-head{background-color:#ffffff;border-color:#414c58;border-radius:5px;border-style:solid;border-width:1px;box-sizing:border-box;color:#414c58;height:100%;line-height:32px;min-height:32px;overflow-x:hidden;overflow-y:hidden;padding:0px;width:100%;}
.-hx-popmenu-body{background-color:#ffffff;border-color:#808892;border-radius:5px;border-style:solid;border-width:1px;box-shadow:0 6px 12px rgba(0, 0, 0, 0.3);box-sizing:border-box;clear:both;color:#414c58;display:none;min-height:30px;overflow-x:hidden;overflow-y:hidden;padding:0px;position:absolute;top:1px;z-index:1000;}
.-hx-popmenu-contents{box-sizing:border-box;height:auto;min-height:100%;min-width:30px;padding:0px 12px 0px 0px;width:100%;}
.-hx-popmenu-arrow-enc{border-color:#cccccc;border-radius:0px 4px 4px 0px;border-style:solid;border-width:0px;height:30px;position:absolute;right:1px;width:22px;}
.-hx-popmenu-arrow{background-image:url(https://manypage-deploy-eu-west-1.s3.amazonaws.com/accounts/helexkids/chrome/chevron-down-oslo.svg?Signature=1DvfALaAgqU%2Fi4tQxb3HmqupSTE%3D&Expires=1893456000&AWSAccessKeyId=AKIAIUN64WOESJR3DPWA&versionId=yiZ1B4bwntHygz96GFAvc9lD6.3RnAQp&vers=20181030200904);background-position:center center;background-repeat:no-repeat;background-size:30px 12px;height:100%;width:100%;}
.-hx-popmenu-item{clear:both;white-space:nowrap;width:100%;}
.-mp-dropmenu-item{background-color:#ffffff;border-color:#eeeeee;border-style:solid;border-width:0px;clear:both;position:relative;width:100%;}
.-mp-dropmenu-divider{border-color:#b3c4cc;border-style:solid;border-width:0px 0px 1px 0px;clear:both;height:0px;margin:5px 0px 5px 0px;width:100%;}
.-hx-popmenu-item:hover{background-color:#e6f7ff;}
.SELECTED.-hx-popmenu-item:hover{background-color:#b8d0ff;color:#ffffff;}
.-hx-popmenu-item-text{font-size:11px;height:30px;line-height:30px;margin:0px 10px 0px 4px;min-width:10px;width:100%;}
.controls-label{border-color:#888888;border-style:solid;border-width:0px;box-sizing:border-box;color:#ffffff;font-size:14px;font-weight:bold;height:22px;line-height:22px;margin:5px 0px 0px 0px;padding:0px 5px 0px 5px;text-align:right;width:80px;}
.-hx-button{border-radius:300px;border-style:solid;border-width:1px;box-shadow:0 3px 6px rgba(0, 0, 0, 0.4);box-sizing:border-box;color:#ffffff;cursor:pointer;height:32px;padding:0px 0px 3px 0px;position:relative;width:auto;}
.-hx-button-action{background-color:#3497db;border-color:#3497db;}
.-hx-button-warning{background-color:#888888;border-color:#888888;}
.-hx-button-label{font-size:12px;height:100%;line-height:30px;text-align:center;text-transform:capitalize;width:100%;}
.-hx-button-success{background-color:#51a351;border-color:#51a351;}
.menu-btn{background-color:#ffffff;border-color:#888888;border-style:solid;border-width:1px 0px 1px 1px;box-sizing:border-box;font-size:14px;height:40px;line-height:2;text-align:center;width:20%;}
.menu-btn-label{color:#444444;font-family:sans-serif;height:100%;line-height:36px;text-align:center;width:100%;}
.menu-btn.DISABLED{background-color:#bebebe;line-height:1.8;opacity:1.000;filter:alpha(opacity=100);text-shadow:0 0px 2px rgba(1,1,1, 0.5);}
.menu-btn.SELECTED{background-color:#bebebe;font-weight:bold;line-height:1.8;opacity:1.000;filter:alpha(opacity=100);text-shadow:0 0px 2px rgba(1,1,1, 0.5);}
.un-enabled{opacity:0.400;filter:alpha(opacity=40);}
.-hx-check-box [name=inner]{height:100%;width:100%;}
.SELECTED.-mp-dropmenu-item{color:#666666;}
.OPEN>.-hx-popmenu-body{display:block;}

.-hx-popmenu-not-body {background-color: lightskyblue;bottom: 0;display: none;left: 0;opacity: 0.5;position: fixed;right: 0;top: 0;z-index: 999;}
.OPEN>.-hx-popmenu-not-body{display:block;}

.-hx-popmenu-head .-hx-popmenu-item:hover{background-color:inherit;color:inherit;}
.PRESSED.-hx-button{border-style:solid;box-shadow:none;top:2px;}
.PRESSED.-hx-check-box{background-color:#ccdde5;}
.CHECKED.-hx-check-box [name=inner]{background-image:url('data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%20standalone%3D%22no%22%3F%3E%3C%21DOCTYPE%20svg%20PUBLIC%20%22-//W3C//DTD%20SVG%201.1//EN%22%20%22http%3A//www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd%22%3E%3Csvg%20width%3D%22100%25%22%20height%3D%22100%25%22%20viewBox%3D%220%200%2022%2018%22%20version%3D%221.1%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20xml%3Aspace%3D%22preserve%22%20style%3D%22fill-rule%3Aevenodd%3Bclip-rule%3Aevenodd%3Bstroke-linejoin%3Around%3Bstroke-miterlimit%3A1.41421%3B%22%3E%3Cpath%20d%3D%22M19.678%2C2.885c0.046%2C0.331%20-0.03%2C0.629%20-0.23%2C0.894l-7.737%2C10.267l-1.453%2C1.929c-0.199%2C0.264%20-0.465%2C0.42%20-0.796%2C0.467c-0.332%2C0.046%20-0.63%2C-0.03%20-0.895%2C-0.23l-1.929%2C-1.453l-5.133%2C-3.868c-0.265%2C-0.2%20-0.42%2C-0.465%20-0.467%2C-0.797c-0.047%2C-0.331%200.03%2C-0.63%200.229%2C-0.894l1.454%2C-1.929c0.199%2C-0.265%200.465%2C-0.42%200.796%2C-0.467c0.332%2C-0.047%200.63%2C0.03%200.895%2C0.229l4.171%2C3.155l7.008%2C-9.316c0.199%2C-0.264%200.465%2C-0.42%200.796%2C-0.467c0.332%2C-0.046%200.63%2C0.03%200.895%2C0.23l1.929%2C1.453c0.264%2C0.2%200.42%2C0.465%200.467%2C0.797Z%22%20style%3D%22fill%3A%233497db%3Bfill-rule%3Anonzero%3B%22/%3E%3C/svg%3E');background-position:center center;background-repeat:no-repeat;background-size:18px 18px;}

.ghostRowPreventsAutoScroll {
  position: absolute;
  background: transparent;
  width: 10%;
  opacity: 0.3;
  right: 18px;
}

/* window styles */
.bilex-menu-window-title-bar{background-color:#c0c6cd;border-color:#ffffff;border-radius:0px 6px 0px 0px;border-style:solid;border-width:0px 0px 1px 0px;box-sizing:content-box;height:32px;width:100%;}
.bilex-window{box-sizing:border-box;font-family:Quicksand;margin:30px;position:relative;}
.bilex-window-body-mini{background-color:#c0c6cd;background-position:center center;background-repeat:no-repeat;border-color:#b6bec5;border-radius:0px 0px 3px 3px;border-style:solid;border-width:0px 1px 1px 1px;box-shadow:rgba(0, 0, 0, 0.4) 0px 0px 40px 3px;box-sizing:border-box;height:100%;margin:24px 0px 0px 0px;min-height:40px;min-width:40px;width:100%;}
.bilex-window-whead-mini{background-color:transparent;border-collapse:collapse;font-size:1px;height:26px;position:absolute;width:100%;}
.bilex-window-title-bar-mini{background-color:#ffffff;border-color:#ffffff;border-radius:0px 4px 0px 0px;border-style:solid;border-width:0px;box-sizing:border-box;height:20px;width:100%;}
.bilex-dialog-title-bar{background-color:#f4f4f4;border-color:#b6bec5;border-radius:0px 3px 0px 0px;border-style:solid;border-width:1px 1px 0px 0px;box-sizing:border-box;height:30px;width:100%;}
.bilex-window-head{box-sizing:border-box;height:32px;width:100%;}
.bilex-window .bilex-window-head{margin:-36px 0px 0px 0px;}
.bilex-window-head-mini{box-sizing:border-box;height:24px;padding:0px 0px 0px 30px;width:100%;}
.bilex-window .bilex-window-head-mini{margin:-24px 0px 0px 0px;}
.bilex-window-head-message{box-sizing:border-box;color:#1f0993;font-size:16px;left:0px;line-height:32px;position:absolute;right:0px;text-align:center;}
.bilex-window-head-message-mini{box-sizing:border-box;color:#3497db;font-size:10px;height:24px;line-height:24px;position:absolute;text-align:left;}
.bilex-window-contents{border-radius:6px 0px 0px 0px;box-sizing:border-box;font-family:Quicksand;font-size:14px;height:100%;overflow-x:hidden;overflow-y:hidden;position:relative;width:100%;}
.bilex-window .bilex-window-contents{height:calc(100% + 4px);}
.-mp-window .-mp-window-contents-mini{}
.bilex-dialog-btns{float:right;height:44px;margin:6px;}
.-mp-window-wlcell{background-color:transparent;border-color:#ff3808;border-style:solid;border-width:0px;height:40px;width:1px;}
.bilex-window-wrcell{background-color:#ff3184;border-color:initial;border-radius:6px 6px 0px 0px;border-style:initial;border-width:initial;box-sizing:border-box;height:auto;overflow-x:hidden;overflow-y:hidden;padding:0px;position:relative;vertical-align:top;}
.bilex-window-wrcell--alert{background-color:#ffffff;}
.bilex-window-wrcell--menu{background-color:#c0c6cd;}
.bilex-window-whead{background-color:transparent;border-collapse:collapse;font-size:1px;height:36px;position:absolute;width:100%;}
.bilex-window-wrow{background-color:transparent;height:auto;width:100%;}
.bilex-window-body{background-color:#c0c6cd;border-radius:0px 0px 6px 6px;box-shadow:rgba(0, 0, 0, 0.4) 0px 0px 40px 3px;box-sizing:border-box;height:calc(100% - 36px);margin:36px 0px 0px 0px;min-height:40px;min-width:40px;width:100%;}
.bilex-window-body--alert{background-color:#ffffff;}
.bilex-window-body--info{background-color:#ffffff;}
.bilex-window-body--menu{background-color:#c0c6cd;}
.bilex-window-title-bar{background-color:#ffffff;border-color:#c0c6cd;border-radius:initial;border-style:solid;border-width:0px 0px 8px 0px;box-sizing:content-box;height:32px;position:absolute;width:100%;}
.bilex-window-title-bar--alert{border-width:0px 0px 1px 0px;}

.home-menu-button-inner{align-items:flex-end;background-color:#ffffff;border-color:#888888;border-radius:16px;border-style:solid;border-width:1px;display:flex;height:138.406px;justify-content:center;min-width:160px;width:215px;}
.home-menu-button-text{color:#ffd00d;font-size:24px;font-weight:bolder;text-align:center;}

/* /bilex-kids/select-language */
.lang-select-button{align-items:center;background-color:#ffffff;border-color:#888888;border-radius:16px;border-style:solid;border-width:1px;cursor:pointer;display:flex;height:100%;justify-content:center;margin:2px 0px 0px 0px;min-width:160px;width:215px;}

/* /bilex-kids/menu */
.kids-menu-link{color:#ffffff;cursor:pointer;font-size:26px;margin:0px 20px 0px 20px;text-align:center;text-transform:uppercase;}

/* /bilex-kids/listing */
.level-en,.level-el{color:#ffffff;font-size:26px;margin:0px 20px 0px 20px;text-align:center;text-transform:uppercase;}

.list-container{box-sizing:border-box;display:flex;flex-direction:column;height:90%;padding:3% 15% 0% 15%;width:100%;}
.list-contents{background-color:#ffffff;border-color:#1f0993;border-radius:30px;border-style:solid;border-width:1px;box-sizing:border-box;flex-grow:1;height:100%;padding:20px;}
.list-contents-results{height:calc(100% - 40px);margin:10px 0px 0px 0px;max-height:100%;overflow-x:hidden;overflow-y:scroll;width:100%;}
.list-contents-results-left {color:#ffd00d;float:left;text-align:right;width:48%;}
.list-contents-results-right {color:#1f0993;float:right;width:48%;}
.listing-sub-head-left {color:#ffd00d;float:left;font-size:20px;text-align:right;text-transform:uppercase;width:48%;}
.listing-sub-head-right {color:#1f0993;float:right;font-size:20px;text-transform:uppercase;width:48%;}


.plus-menu-link{align-items:center;background-color:transparent;border-color:transparent;border-radius:100px;border-style:solid;border-width:2px;display:flex;flex-direction:column;height:120px;justify-content:flex-end;left:72px;min-width:160px;position:absolute;top:62px;width:100px;}
.plus-menu-link-text{color:#000000;font-size:20px;line-height:40px;text-transform:uppercase;}

.plus-level-menu-link{color:#222222;cursor:pointer;font-size:16px;margin:0px 20px 0px 20px;text-align:left;text-transform:uppercase;}

/* bilex-plus/info */
.help-div{}
.help-div p{font-size:14px;margin:0px 0px 10px 0px;}
span.help-head-blue{color:#1f0993;}
span.help-head-orange{color:#ee8a01;}

/* bilex-plus/about */
.menu-link{align-items:center;background-color:transparent;border-color:transparent;border-radius:100px;border-style:solid;border-width:2px;display:flex;flex-direction:column;height:120px;justify-content:flex-end;left:72px;min-width:160px;position:absolute;top:62px;width:100px;}
.menu-link-text{font-size:20px;line-height:40px;text-transform:uppercase;}
.people-link{}
.people-link-name{color:#1f0993;text-align:center;width:100%;}
.people-link-link{color:#444444;font-size:10px;height:30px;line-height:20px;text-align:center;width:100%;}

/* make <button> look like link */
button{background:none;border:none;font-weight:bold;font-family:Quicksand}